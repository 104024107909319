var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.instructionsClass }, [
    _c("div", [
      _c("strong", [_vm._v(" " + _vm._s(_vm.txt.forBetterResults) + " ")]),
    ]),
    _c("ul", [
      _c("li", [_vm._v(_vm._s(_vm.txt.instruction1))]),
      _c("li", [_vm._v(_vm._s(_vm.txt.instruction2))]),
    ]),
    _vm.isTooltip ? _c("br") : _vm._e(),
    _c("div", [_c("strong", [_vm._v(" " + _vm._s(_vm.txt.important) + " ")])]),
    _c("ul", [
      _c("li", [_vm._v(" " + _vm._s(_vm.txt.importantInstruction1) + " ")]),
      _c("li", [_vm._v(" " + _vm._s(_vm.txt.importantInstruction2) + " ")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }