<template>
  <div :class="instructionsClass">
    <div>
      <strong>
        {{ txt.forBetterResults }}
      </strong>
    </div>
    <ul>
      <li>{{ txt.instruction1 }}</li>
      <li>{{ txt.instruction2 }}</li>
    </ul>
    <br v-if="isTooltip" />
    <div>
      <strong>
        {{ txt.important }}
      </strong>
    </div>
    <ul>
      <li>
        {{ txt.importantInstruction1 }}
      </li>
      <li>
        {{ txt.importantInstruction2 }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CboSearchInstructions',

  props: {
    isTooltip: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      txt: {
        forBetterResults: 'Para obter os melhores resultados, siga as orientações:',
        instruction1: 'Forneça informações detalhadas sobre as atividades realizadas pelo trabalhador.',
        instruction2: 'Evite descrições obtidas de websites.',
        important: 'Importante:',
        importantInstruction1:
          'Atente-se para os CBOs que requerem alguma formação específica ou ainda registro em órgão de classe;',

        importantInstruction2:
          'As sugestões fornecidas são recomendações baseadas nas descrições fornecidas. O devido enquadramento das atividades são de responsabilidade do empregador. Em caso de dúvidas, entre em contato via chat ou suporte@agnes.com.br',
      },
    };
  },

  computed: {
    instructionsClass() {
      return this.isTooltip ? 'cbo-search-instructions-tooltip' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.cbo-search-instructions-tooltip {
  line-height: 133%;
  letter-spacing: 0.12px;
}
ul {
  list-style-position: outside;
}
</style>
